import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout-main";
import Img from "gatsby-image";

/** @jsx jsx */
import { jsx } from "theme-ui";

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const featuredImgFluid =
    post.frontmatter.featuredImage?.childImageSharp.fluid;
  const { previous, next } = pageContext;

  return (
    <Layout
      location={location}
      pageTitle={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    >
      <section>
        <div className="aw-content">
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
            sx={{ marginBottom: "40px" }}
          >
            <header>
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <h2 sx={{ marginTop: "0", fontWeight: "400" }}>
                {post.frontmatter.subtitle}
              </h2>
              {featuredImgFluid && (
                <Img
                  sx={{ maxWidth: "600px", marginBottom: "20px" }}
                  fluid={featuredImgFluid}
                />
              )}
            </header>
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
            {post.frontmatter.author && (
              <>
                <small>Written by {post.frontmatter.author}</small>
                <br />
              </>
            )}
            <small>Published on {post.frontmatter.date}</small>
          </article>
          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li sx={{ listStyle: "none" }}>
                {previous && (
                  <Link to={"/blog" + previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li sx={{ listStyle: "none" }}>
                {next && (
                  <Link to={"/blog" + next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        subtitle
        date(formatString: "MMMM D, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
